import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { MenuIcon, MailIcon, XIcon, CheckIcon } from '@heroicons/react/outline'
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/seo"

import "../components/styles.css"

export default function IndexPage() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <Fragment>
      <SEO title="Tidy Creations | Everyone deserves a tidy home" />
      <div className="bg-white">
      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setMobileMenuOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
              <div className="px-4 pt-5 pb-2 flex">
                <button
                  type="button"
                  className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                <div className="flow-root">
                  <a href="mailto:hello@tidycreations.com" className="-m-2 p-2 block font-medium text-gray-900">
                    Contact us
                  </a>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      {/* Hero section */}
      <div className="relative bg-gray-900">
        <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
          <StaticImage
            src="../images/hero.jpg"
            alt="Tidy home"
            className="w-full h-full object-center object-cover"
          />
        </div>
        <div aria-hidden="true" className="absolute inset-0 bg-gray-900 opacity-70" />

        {/* Navigation */}
        <header className="relative z-10">
          <nav aria-label="Top">
            {/* Top navigation */}
            <div className="bg-gray-900 hidden">
              <div className="max-w-7xl mx-auto h-2 px-4 flex items-center justify-between sm:px-6 lg:px-8">
              </div>
            </div>

            {/* Secondary navigation */}
            <div className="pt-8">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div>
                  <div className="h-16 flex items-center justify-between">
                    {/* Logo (lg+) */}
                    <div className="hidden lg:flex-1 lg:flex lg:items-center">
                      <a href="/">
                        <svg className="w-20" viewBox="0 0 265 167">
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g transform="translate(0.810000, 0.573171)" fill="#FFFFFF" fillRule="nonzero">
                              <path d="M34.82,122.086829 C27.49,122.086829 27.33,114.236829 27.32,113.936829 L27.32,77.4068293 L49.05,77.4068293 C49.45,77.4068293 49.77,77.0868293 49.77,76.6868293 L49.77,63.8568293 C49.77,63.4568293 49.45,63.1368293 49.05,63.1368293 L27.32,63.1368293 L27.32,45.5768293 C27.32,45.1268293 26.91,44.7868293 26.47,44.8668293 L13.9134209,47.0466488 C13.4367324,47.1294019 13.0876559,47.5415488 13.084484,48.0253566 L12.99,62.4368293 L12.99,62.4368293 L0.72,62.4368293 C0.32,62.4368293 -5.68434189e-14,62.7568293 -5.68434189e-14,63.1568293 L-5.68434189e-14,75.9868293 C-5.68434189e-14,76.3868293 0.32,76.7068293 0.72,76.7068293 L12.99,76.7068293 L12.99,113.226829 C12.99,130.036829 24.94,135.996829 35.18,135.996829 C43.42,135.996829 50.41,132.116829 53.04,130.446829 L54.4069056,129.498645 C54.8176038,129.213755 54.9548724,128.669372 54.7283615,128.223807 L49.8856489,118.697807 C49.6463603,118.227091 49.0823229,118.023553 48.5975882,118.232995 C42.6513349,120.802218 38.0588055,122.086829 34.82,122.086829 Z" id="Path"></path>
                              <path d="M161.19,71.4268293 C154.97,65.8068293 145.58,61.7468293 136.76,61.7468293 C117.14,61.7468293 101.18,78.5568293 101.18,99.2168293 C101.18,119.876829 117.14,136.686882 136.76,136.686882 C145.58,136.686882 154.96,134.036829 161.19,128.426829 C161.19,131.744691 161.19,134.233087 161.19,135.892018 C161.19,136.130414 161.56517,136.607205 161.970155,136.607205 C162.971098,136.607205 167.183198,136.607205 174.606456,136.607205 C174.902717,136.607205 175.51104,136.313842 175.511826,135.729067 C175.519419,130.081813 175.55767,97.2573888 175.510582,39.0123968 C175.51039,38.7749592 175.412828,38.2976456 174.800839,38.2965403 C173.696078,38.2965403 169.474806,38.2965403 161.776277,38.2965403 C161.641979,38.2965403 161.19,38.51819 161.19,39.0707081 C161.19,40.9789694 161.19,51.7643431 161.19,71.4268293 Z M159.88,99.3068293 C159.86,111.836829 150.26,122.016829 138.45,122.016829 C126.63,122.016829 117.02,111.816829 117.02,99.2768293 C117.02,86.7368293 126.63,76.5368293 138.45,76.5368293 C150.26,76.5368293 159.86,86.7268293 159.88,99.2468293 L159.88,99.3068293 L159.88,99.3068293 Z" id="Shape"></path>
                              <path d="M239.91,64.0168293 L239.91,105.206829 C239.91,113.586829 233.7,120.916829 225.36,121.706829 C215.79,122.606829 207.71,115.066829 207.71,105.676829 L207.71,63.8768293 C207.71,63.4768293 207.39,63.1568293 206.99,63.1568293 L192.8,63.1568293 C192.4,63.1568293 192.08,63.4768293 192.08,63.8768293 L192.08,104.176829 C192.08,121.356829 205.42,136.016829 222.59,136.666829 C240.64,137.346829 255.54,122.866829 255.54,104.956829 C255.54,87.1307663 255.54,73.7612191 255.54,64.8481876 C255.54,64.5710682 255.166137,64.0168293 254.68,64.0168293 L240.78,64.0168293 C240.29,64.0168293 239.91,64.4068293 239.91,64.8768293 L239.91,64.0168293 Z" id="Path"></path>
                              <path d="M248.647914,143.255099 C250.202747,140.786686 253.069991,139.277032 256.167333,139.602575 C260.509102,140.058914 263.658865,143.948548 263.202527,148.290316 C263.002511,150.193347 262.142911,151.867377 260.874758,153.109127 L260.817131,153.16909 C251.459783,162.681223 236.836096,167.115109 223.249631,165.687114 C209.598424,164.252314 197.647072,156.96481 190.117385,145.597477 L190.117385,145.597477 L190.020109,145.437394 C189.569476,144.622881 189.654489,143.607656 190.251262,142.877127 C191.052558,141.896234 192.497307,141.750643 193.4782,142.551939 L193.4782,142.551939 L194.015045,142.986724 C203.496748,150.597645 212.821519,154.510092 221.989358,154.724065 C236.658242,155.066431 244.13361,149.276279 248.347297,143.6307 C248.424276,143.527563 248.503754,143.425899 248.587962,143.325595 Z" id="Combined-Shape"></path>
                              <path d="M86.19,135.426829 L86.19,64.4268293 C86.19,63.8745445 85.7422847,63.4268293 85.19,63.4268293 L71.19,63.4268293 C70.6377153,63.4268293 70.19,63.8745445 70.19,64.4268293 L70.19,135.426829 C70.19,135.979114 70.6377153,136.426829 71.19,136.426829 L85.19,136.426829 C85.7422847,136.426829 86.19,135.979114 86.19,135.426829 Z" id="Path"></path>
                              <path d="M78.5888369,51.6303901 L78.2116993,56 C78.2116993,49.7734482 75.5336419,39.9599429 70.9356115,35.3064147 C66.3375812,30.6528865 59.9398536,28.2874743 53.78375,28.2874743 L53.78375,27.5975359 C59.9398536,27.5975359 66.3375812,25.2321238 70.9356115,20.5670292 C74.0009651,17.4569662 76.4263277,10.6012898 78.2116993,0 C79.997071,10.6167116 82.4224336,17.4762434 85.4877871,20.5785956 C90.0858175,25.2321238 95.4609797,27.5608156 101.617083,27.5608156 L101.617083,28.3241947 C95.4647892,28.3241947 90.0858175,30.6528865 85.4877871,35.3064147 C80.8897568,39.9599429 78.5525544,49.7695927 78.5525544,56 L78.5888369,51.6303901 Z" id="Path" transform="translate(77.700417, 28.000000) rotate(-360.000000) translate(-77.700417, -28.000000) "></path>
                            </g>
                          </g>
                        </svg>
                      </a>
                    </div>

                    {/* Mobile menu (lg-) */}
                    <div className="flex-1 flex items-center lg:hidden">
                      <button type="button" className="-ml-2 p-2 text-white" onClick={() => setMobileMenuOpen(true)}>
                        <span className="sr-only">Open menu</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>

                    {/* Logo (lg-) */}
                    <a href="/" className="lg:hidden">
                      <svg className="w-20" viewBox="0 0 265 167">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <g transform="translate(0.810000, 0.573171)" fill="#FFFFFF" fillRule="nonzero">
                            <path d="M34.82,122.086829 C27.49,122.086829 27.33,114.236829 27.32,113.936829 L27.32,77.4068293 L49.05,77.4068293 C49.45,77.4068293 49.77,77.0868293 49.77,76.6868293 L49.77,63.8568293 C49.77,63.4568293 49.45,63.1368293 49.05,63.1368293 L27.32,63.1368293 L27.32,45.5768293 C27.32,45.1268293 26.91,44.7868293 26.47,44.8668293 L13.9134209,47.0466488 C13.4367324,47.1294019 13.0876559,47.5415488 13.084484,48.0253566 L12.99,62.4368293 L12.99,62.4368293 L0.72,62.4368293 C0.32,62.4368293 -5.68434189e-14,62.7568293 -5.68434189e-14,63.1568293 L-5.68434189e-14,75.9868293 C-5.68434189e-14,76.3868293 0.32,76.7068293 0.72,76.7068293 L12.99,76.7068293 L12.99,113.226829 C12.99,130.036829 24.94,135.996829 35.18,135.996829 C43.42,135.996829 50.41,132.116829 53.04,130.446829 L54.4069056,129.498645 C54.8176038,129.213755 54.9548724,128.669372 54.7283615,128.223807 L49.8856489,118.697807 C49.6463603,118.227091 49.0823229,118.023553 48.5975882,118.232995 C42.6513349,120.802218 38.0588055,122.086829 34.82,122.086829 Z" id="Path"></path>
                            <path d="M161.19,71.4268293 C154.97,65.8068293 145.58,61.7468293 136.76,61.7468293 C117.14,61.7468293 101.18,78.5568293 101.18,99.2168293 C101.18,119.876829 117.14,136.686882 136.76,136.686882 C145.58,136.686882 154.96,134.036829 161.19,128.426829 C161.19,131.744691 161.19,134.233087 161.19,135.892018 C161.19,136.130414 161.56517,136.607205 161.970155,136.607205 C162.971098,136.607205 167.183198,136.607205 174.606456,136.607205 C174.902717,136.607205 175.51104,136.313842 175.511826,135.729067 C175.519419,130.081813 175.55767,97.2573888 175.510582,39.0123968 C175.51039,38.7749592 175.412828,38.2976456 174.800839,38.2965403 C173.696078,38.2965403 169.474806,38.2965403 161.776277,38.2965403 C161.641979,38.2965403 161.19,38.51819 161.19,39.0707081 C161.19,40.9789694 161.19,51.7643431 161.19,71.4268293 Z M159.88,99.3068293 C159.86,111.836829 150.26,122.016829 138.45,122.016829 C126.63,122.016829 117.02,111.816829 117.02,99.2768293 C117.02,86.7368293 126.63,76.5368293 138.45,76.5368293 C150.26,76.5368293 159.86,86.7268293 159.88,99.2468293 L159.88,99.3068293 L159.88,99.3068293 Z" id="Shape"></path>
                            <path d="M239.91,64.0168293 L239.91,105.206829 C239.91,113.586829 233.7,120.916829 225.36,121.706829 C215.79,122.606829 207.71,115.066829 207.71,105.676829 L207.71,63.8768293 C207.71,63.4768293 207.39,63.1568293 206.99,63.1568293 L192.8,63.1568293 C192.4,63.1568293 192.08,63.4768293 192.08,63.8768293 L192.08,104.176829 C192.08,121.356829 205.42,136.016829 222.59,136.666829 C240.64,137.346829 255.54,122.866829 255.54,104.956829 C255.54,87.1307663 255.54,73.7612191 255.54,64.8481876 C255.54,64.5710682 255.166137,64.0168293 254.68,64.0168293 L240.78,64.0168293 C240.29,64.0168293 239.91,64.4068293 239.91,64.8768293 L239.91,64.0168293 Z" id="Path"></path>
                            <path d="M248.647914,143.255099 C250.202747,140.786686 253.069991,139.277032 256.167333,139.602575 C260.509102,140.058914 263.658865,143.948548 263.202527,148.290316 C263.002511,150.193347 262.142911,151.867377 260.874758,153.109127 L260.817131,153.16909 C251.459783,162.681223 236.836096,167.115109 223.249631,165.687114 C209.598424,164.252314 197.647072,156.96481 190.117385,145.597477 L190.117385,145.597477 L190.020109,145.437394 C189.569476,144.622881 189.654489,143.607656 190.251262,142.877127 C191.052558,141.896234 192.497307,141.750643 193.4782,142.551939 L193.4782,142.551939 L194.015045,142.986724 C203.496748,150.597645 212.821519,154.510092 221.989358,154.724065 C236.658242,155.066431 244.13361,149.276279 248.347297,143.6307 C248.424276,143.527563 248.503754,143.425899 248.587962,143.325595 Z" id="Combined-Shape"></path>
                            <path d="M86.19,135.426829 L86.19,64.4268293 C86.19,63.8745445 85.7422847,63.4268293 85.19,63.4268293 L71.19,63.4268293 C70.6377153,63.4268293 70.19,63.8745445 70.19,64.4268293 L70.19,135.426829 C70.19,135.979114 70.6377153,136.426829 71.19,136.426829 L85.19,136.426829 C85.7422847,136.426829 86.19,135.979114 86.19,135.426829 Z" id="Path"></path>
                            <path d="M78.5888369,51.6303901 L78.2116993,56 C78.2116993,49.7734482 75.5336419,39.9599429 70.9356115,35.3064147 C66.3375812,30.6528865 59.9398536,28.2874743 53.78375,28.2874743 L53.78375,27.5975359 C59.9398536,27.5975359 66.3375812,25.2321238 70.9356115,20.5670292 C74.0009651,17.4569662 76.4263277,10.6012898 78.2116993,0 C79.997071,10.6167116 82.4224336,17.4762434 85.4877871,20.5785956 C90.0858175,25.2321238 95.4609797,27.5608156 101.617083,27.5608156 L101.617083,28.3241947 C95.4647892,28.3241947 90.0858175,30.6528865 85.4877871,35.3064147 C80.8897568,39.9599429 78.5525544,49.7695927 78.5525544,56 L78.5888369,51.6303901 Z" id="Path" transform="translate(77.700417, 28.000000) rotate(-360.000000) translate(-77.700417, -28.000000) "></path>
                          </g>
                        </g>
                      </svg>
                    </a>

                    <div className="flex-1 flex items-center justify-end">
                      <a href="mailto:hello@tidycreations.com" target="blank" className="hidden font-medium text-white lg:block">
                        Contact us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>

        <div className="relative max-w-4xl mx-auto py-32 px-6 flex flex-col items-center text-center sm:py-64 lg:px-0">
          <h1 className="text-4xl font-semibold tracking-tight text-white lg:text-6xl">Everyone deserves a tidy home</h1>
          <p className="mt-8 text-xl text-white">
            We design and create simple, functional and elegant
            household products that keeps your home tidy at an affordable price
          </p>
        </div>
      </div>

      <main>
        <section
          aria-labelledby="collection-heading"
          className="max-w-xl mx-auto pt-24 px-4 sm:pt-32 sm:px-6 lg:max-w-7xl lg:px-8"
        >
          <h2 id="collection-heading" className="text-2xl font-semibold tracking-tight text-gray-900">
            Unveil the true potential of your home
          </h2>
          <p className="mt-4 text-base text-gray-500">
            Times has changed in a big way for all of us in the past few years. Living and working at home is the new normal.
            It is now more important than ever to keep things tidy and organized to promote a better living and working environment.
          </p>
          <p className="mt-4 text-base text-gray-500">
            At Tidy creations we believe that everyone deserves to have a tidy and organized home.
            Whether it’s for your home office, closet or kitchen cabinets, our wide range of smart and elegant organization products are created to solve your clutter issues without breaking the bank.
          </p>

          <div className="mt-10 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-x-8">

            <div className="group block">
              <div
                aria-hidden="true"
                className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden lg:aspect-w-5 lg:aspect-h-6"
              >
                <StaticImage
                  src="../images/home-office.jpg"
                  alt="Home / Office"
                  className="w-full h-full object-center object-cover"
                />
              </div>
              <h3 className="mt-4 text-base font-semibold text-gray-900">Home Office</h3>
              <p className="mt-2 text-sm text-gray-500">Everything you need to create a comfortable and productive environment</p>
            </div>

            <div className="group block">
              <div
                aria-hidden="true"
                className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden lg:aspect-w-5 lg:aspect-h-6"
              >
                <StaticImage
                  src="../images/kitchen.jpg"
                  alt="Kitchen"
                  className="w-full h-full object-center object-cover"
                />
              </div>
              <h3 className="mt-4 text-base font-semibold text-gray-900">Kitchen</h3>
              <p className="mt-2 text-sm text-gray-500">
                Easily find the things you need in a clean and organized kitchen
              </p>
            </div>

            <div className="group block">
              <div
                aria-hidden="true"
                className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden lg:aspect-w-5 lg:aspect-h-6"
              >
                <StaticImage
                  src="../images/wardrobe.jpg"
                  alt="Wardrobe"
                  className="w-full h-full object-center object-cover"
                />
              </div>
              <h3 className="mt-4 text-base font-semibold text-gray-900">Closet / Wardrobe</h3>
              <p className="mt-2 text-sm text-gray-500">
                Baskets, boxes, hangers. We have what you need to keep your closet organized.
              </p>
            </div>

            <div className="group block">
              <div
                aria-hidden="true"
                className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden lg:aspect-w-5 lg:aspect-h-6"
              >
                <StaticImage
                  src="../images/bathroom.jpg"
                  alt="Bathroom"
                  className="w-full h-full object-center object-cover"
                />
              </div>
              <h3 className="mt-4 text-base font-semibold text-gray-900">Bathroom</h3>
              <p className="mt-2 text-sm text-gray-500">
               Products to organize your towels, toiletries and everything in between
              </p>
            </div>

          </div>
        </section>

        <section aria-labelledby="comfort-heading" className="max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <div className="relative rounded-lg overflow-hidden">
            <div className="absolute inset-0">
              <StaticImage
                  src="../images/organized.jpg"
                  alt="Organized"
                  className="w-full h-full object-center object-cover"
                />
            </div>
            <div className="relative bg-gray-900 bg-opacity-80 p-16">
              <h2 id="collection-heading" className="text-2xl font-semibold tracking-tight text-white">
                Our guiding principles
              </h2>
              <p className="mt-4 text-base text-white">
                Nothing speaks louder than the quality of our products. Every aspect of our products has been meticulously thought out to
                offer you the best in design, functionality and overall quality. Here are our 5 core principles that guides our design and product development process.
              </p>
              <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8">
                <div className="relative">
                  <dt>
                    <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                    <p className="ml-9 text-lg leading-6 font-medium text-white">Aesthetically pleasing</p>
                  </dt>
                </div>

                <div className="relative">
                  <dt>
                    <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                    <p className="ml-9 text-lg leading-6 font-medium text-white">Functional & easy to use</p>
                  </dt>
                </div>

                <div className="relative">
                  <dt>
                    <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                    <p className="ml-9 text-lg leading-6 font-medium text-white">Durable</p>
                  </dt>
                </div>

                <div className="relative">
                  <dt>
                    <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                    <p className="ml-9 text-lg leading-6 font-medium text-white">Space Saving</p>
                  </dt>
                </div>

                <div className="relative">
                  <dt>
                    <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                    <p className="ml-9 text-lg leading-6 font-medium text-white">Affordable</p>
                  </dt>
                </div>

              </dl>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-gray-900">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center space-x-6 md:order-2">

            <a href="https://www.instagram.com/shop.tidycreations/" target="blank" className="text-white hover:text-gray-500">
              <span className="sr-only">Instagram</span>
              <svg fill="currentColor" viewBox="0 0 24 24" className="h-6 w-6" aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            </a>

            <a href="mailto:hello@tidycreations.com" className="text-white hover:text-gray-500">
              <span className="sr-only">Email</span>
              <MailIcon className="h-6 w-6" aria-hidden="true" />
            </a>

          </div>
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base text-white">&copy; {new Date().getFullYear()} Tidy Creations. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
    </Fragment>
  )
}
